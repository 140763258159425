import React from 'react'

import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import ExchangeGraphic from '../../images/721.svg'
import SectionLayout from '../SharedComponents/SectionLayout'

type SellVsFlockHeroSectionProps = {
  title?: string
  description?: string
}

const SellVsFlockHeroSection = (props: SellVsFlockHeroSectionProps) => {
  const { title, description } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionLayout
      name="sell-versus-flock-hero"
      backgroundColor="trustBlue.main"
    >
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        flexDirection="column"
        gap="57px"
      >
        <Typography variant="h1" color="moneyGreen.main">
          {title}
        </Typography>
        <Typography variant="h4">{description}</Typography>
      </Grid>
      {!isTablet && <Grid item sm={1} />}
      <Grid item xs={12} md={5} display="flex" justifyContent="center">
        <Box
          component="img"
          src={ExchangeGraphic}
          width={isMobile ? 303 : 448}
          height={isMobile ? 291 : 430}
          alt="How Flock works"
        />
      </Grid>
    </SectionLayout>
  )
}

export default SellVsFlockHeroSection

SellVsFlockHeroSection.defaultProps = {
  title: 'The Flock Sales Calculator',
  description:
    'Selling your investment property the traditional way can be expensive. There are costs to ready, stage, and sell the property, often with a realtor, and tax bills to consider after the sale.',
}
