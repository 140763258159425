import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import SellVsFlockHeroSection from '../components/SellVersusFlockComponents/SellVsFlockHeroSection'
import SellVsFlockInputsSection from '../components/SellVersusFlockComponents/SellVsFlockInputsSection'
import InlineMessageAddress from '../components/SharedComponents/InlineMessageAddress'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import SectionLayout from '../components/SharedComponents/SectionLayout'
import { WebinarModal } from '../components/SharedComponents/WebinarModal'
import { useRecordPageDuration } from '../components/utils'

const SellVersusFlock = () => {
  const defaultInputs = {
    currentHouseValue: 400000,
    purchasePrice: 200000,
    dateOfPurchase: new Date(),
    monthlyRent: 3200,
    outstandingMortgageAmount: 0,
  }

  const inputs: any = {
    ...defaultInputs,
  }

  if (typeof window !== 'undefined') {
    const { search } = window.location
    const params = new URLSearchParams(search)

    Object.keys(defaultInputs).forEach((key) => {
      if (key === 'dateOfPurchase' && params.get(key)) {
        inputs[key] = new Date(params.get(key) as string | number)
      } else if (params.get(key)) {
        inputs[key] = parseFloat(params.get(key) as string)
      }
    })
  }

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  useRecordPageDuration()

  return (
    <PageWrapper
      title="Flock | Sell versus Flock"
      trackingName="sell-vs-flock"
      headerBackground="trustBlue.main"
    >
      <SellVsFlockHeroSection />
      <SellVsFlockInputsSection {...inputs} />
      <SectionLayout name="inline-message" backgroundColor="trustBlue.main">
        <Grid item xs={12}>
          <InlineMessageAddress
            text="Landlords, retire and minimize taxes."
            variant={isTablet ? 'button' : 'address'}
            label="Get Started"
          />
        </Grid>
        <Grid item xs={12} textAlign="end" pt="16px">
          <WebinarModal />
        </Grid>
      </SectionLayout>
    </PageWrapper>
  )
}

export default SellVersusFlock
