import {
  CircleCheckedIcon,
  CircleUncheckedIcon,
  DatePicker,
} from '@flock/shared-ui'
import { sellVersusFlockCalculator } from '@flock/utils'
import {
  Checkbox,
  debounce,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Controller, useForm, useWatch } from 'react-hook-form'
import ControlledTextField from '../CashFlowComponents/ControlledTextField'
import SectionLayout from '../SharedComponents/SectionLayout'
import SellVsFlockComparisonSection from './SellVsFlockComparisonSection'
import SellVsFlockTable from './SellVsFlockTable'

type SellVsFlockInputsSectionProps = {
  currentHouseValue: number
  purchasePrice: number
  dateOfPurchase: Date
  monthlyRent: number
  outstandingMortgageAmount: number

  title?: string
}

const SellVsFlockInputsSection = (props: SellVsFlockInputsSectionProps) => {
  const { title, ...defaultInputs } = props
  const [sellingNetValue, setSellingNetValue] = useState(0)
  const [contributingNetValue, setContributingNetValue] = useState(0)

  const [sellingCosts, setSellingCosts] = useState<any>()
  const [flockCosts, setFlockCosts] = useState<any>()

  const { control, formState } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultInputs,
      hasMortgage: defaultInputs.outstandingMortgageAmount > 0,
    },
  })

  const fields = useWatch({ control })

  const updateFields = (newFields: any) => {
    const {
      currentHouseValue,
      purchasePrice,
      dateOfPurchase,
      monthlyRent,
      outstandingMortgageAmount,
      hasMortgage,
    } = newFields

    const calculatorInputs: any = {
      currentHouseValue,
      purchasePrice,
      dateOfPurchase,
      monthlyRent,
      outstandingMortgageAmount: 0,
    }

    if (hasMortgage) {
      calculatorInputs.outstandingMortgageAmount = outstandingMortgageAmount
    }

    const calculatorOutput = sellVersusFlockCalculator(calculatorInputs)
    const { selling, contributingToFlock } = calculatorOutput
    setSellingNetValue(selling.netValue)
    setContributingNetValue(contributingToFlock.netValue)

    setSellingCosts(selling)
    setFlockCosts(contributingToFlock)

    const queryString = Object.keys(calculatorInputs)
      .map((key) => `${key}=${calculatorInputs[key].toString()}`)
      .join('&')
    const newPath = `/sell-versus-flock/?${queryString}`
    window.history.replaceState(null, '', newPath)
  }

  const debounceUpdateFields = useMemo(() => debounce(updateFields, 1000), [])

  useEffect(() => {
    if (formState.isValid) {
      debounceUpdateFields(fields)
    }
  }, [fields, debounceUpdateFields, formState.isValid])

  return (
    <SectionLayout
      name="sell-versus-flock-calculator-inputs"
      backgroundColor="trustBlue.main"
      verticalPadding="0px"
    >
      <Grid item xs={12}>
        <Typography variant="h3">{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <ControlledTextField
          name="currentHouseValue"
          label="House Value"
          format="dollars"
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ControlledTextField
          name="purchasePrice"
          label="Purchase Price"
          format="dollars"
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <ControlledTextField
          name="monthlyRent"
          label="Monthly Rent"
          format="dollars"
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={({ field }) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { ref, ...otherFieldProps } = field
            return (
              <DatePicker
                size="small"
                fullWidth
                label="Original Purchase Date"
                helperText=""
                {...otherFieldProps}
                disableFuture
              />
            )
          }}
          control={control}
          name="dateOfPurchase"
        />
      </Grid>
      <Grid item xs={12} sx={{ zIndex: 99 }}>
        <FormControlLabel
          label="I have a mortgage"
          control={
            <Controller
              name="hasMortgage"
              control={control}
              render={({ field: fieldProps }) => (
                <Checkbox
                  {...fieldProps}
                  checked={fieldProps.value}
                  onChange={(e) => fieldProps.onChange(e.target.checked)}
                  checkedIcon={<CircleCheckedIcon />}
                  icon={<CircleUncheckedIcon />}
                />
              )}
            />
          }
        />
      </Grid>
      {fields.hasMortgage && (
        <Grid item xs={12} sm={3}>
          <ControlledTextField
            name="outstandingMortgageAmount"
            label="Mortgage Amount"
            format="dollars"
            control={control}
            disabled={!fields.hasMortgage}
          />
        </Grid>
      )}
      <Grid item xs={12} container spacing={isMobile ? 0 : 4}>
        <SellVsFlockComparisonSection
          sellingNetValue={sellingNetValue}
          contributingNetValue={contributingNetValue}
        />
      </Grid>
      <Grid item xs={12}>
        <SellVsFlockTable sellCosts={sellingCosts} flockCosts={flockCosts} />
      </Grid>
    </SectionLayout>
  )
}

export default SellVsFlockInputsSection

SellVsFlockInputsSection.defaultProps = {
  title:
    'Flock offers landlords a better, cheaper way to sell. Explore your potential savings using Flock instead of a traditional sale.',
}
